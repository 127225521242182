/*******************************
         Site Overrides
*******************************/

@font-path: "../../../../public/fonts";

   @font-face {
  font-family: "SKF-Chevin";
  src: url("@{font-path}/skfchevinot-medium-webfont.ttf") format("truetype");
  /* IE */ }
@font-face {
  font-family: "SKF-Chevin";
  src: url("@{font-path}/skfchevinot-bold-webfont.ttf") format("truetype");
  /* IE */
  font-weight: bold; }
@font-face {
  font-family: "SKF-Chevin";
  src: url("@{font-path}/skfchevinot-light-webfont.ttf") format("truetype");
  /* IE */
  font-weight: lighter; }
@font-face {
  font-family: "SKF-Chevin";
  src: url("@{font-path}/skfchevinot-lightitalic-webfont.ttf") format("truetype");
  /* IE */
  font-style: italic; }
